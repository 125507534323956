<template>
  <div class="logisticsSet">
    <div class="container">
      <div class="title">物流设置</div>
      <div class="top">
        <div>
          <i
            class="el-icon-box"
            style="font-size: 24px; color: #999999; margin-top: 20px"
          ></i>
        </div>
        <div style="margin-left: 20px; width: 100%">
          <div style="font-size: 18px">物流方式</div>
          <div
            style="
              font-size: 14px;
              color: #999999;
              margin-top: 10px;
              padding-bottom: 30px;
              border-bottom: 1px solid #e8e8e8;
              margin-bottom: 40px;
            "
          >
            通过Shopee支持的物流选项，买家将收到Shopee发出有关包裹最新的跟踪信息通知。
          </div>
          <div
            class="cash-cell"
            v-for="item in listData.shipping_list"
            :key="item.id"
          >
            <div class="name">
              <div style="font-size: 14px">
                <span>{{ item.name }}</span>
                <span
                  v-if="item.is_pay_delivery == 1"
                  style="margin-left: 10px; color: #ee4d2d"
                  >[货到付款开启]</span
                >
              </div>
              <div>
                <el-switch
                  v-model="item.is_pay_delivery"
                  style="margin-right: 30px"
                  active-color="#13ce66"
                  :width="40"
                  @change="editSet(item.id, item.is_pay_delivery, 2)"
                  :active-value="1"
                  :inactive-value="0"
                >
                </el-switch>
                <i
                  @click="open(item)"
                  v-if="item.open"
                  class="el-icon-arrow-up"
                  style="cursor: pointer"
                ></i>
                <i
                  style="cursor: pointer"
                  @click="open(item)"
                  v-else
                  class="el-icon-arrow-down"
                ></i>
              </div>
            </div>
            <div class="cell-hidden" v-if="item.open">
              <div style="width: 54%; border-right: 1px solid #e8e8e8">
                <div style="margin-top: 20px; font-size: 12px; color: #8c8c8c">
                  限制
                </div>
                <div style="font-size: 14px">
                  最大重量：{{ item.limit_max_weight }}公斤
                </div>
              </div>
              <div style="" class="r-item">
                <div>
                  <span> 开启这个物流商</span>
                  <el-switch
                    v-model="item.is_express_provider"
                    active-color="#13ce66"
                    :active-value="1"
                    :inactive-value="0"
                    @change="editSet(item.id, item.is_express_provider, 1)"
                  >
                  </el-switch>
                </div>
                <div>
                  <span> 开启货到付款</span>
                  <el-switch
                    v-model="item.is_pay_delivery"
                    active-color="#13ce66"
                    :active-value="1"
                    :inactive-value="0"
                    @change="editSet(item.id, item.is_pay_delivery, 2)"
                  >
                  </el-switch>
                </div>
                <div style="border-top: 1px solid #e8e8e8">
                  <span> 设为首选的物流方式</span>
                  <el-switch
                    v-model="item.is_default"
                    active-color="#13ce66"
                    :active-value="1"
                    :inactive-value="0"
                    @change="editSet(item.id, item.is_default, 3)"
                  >
                  </el-switch>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="top">
        <div>
          <i
            class="el-icon-date"
            style="font-size: 24px; color: #999999; margin-top: 40px"
          ></i>
        </div>
        <div
          style="
            margin-left: 20px;
            margin-right: 30px;
            width: 100%;
            border-top: 1px solid #e8e8e8;
            padding-top: 20px;
          "
        >
          <div style="font-size: 18px">首公里设置</div>
          <div
            style="
              font-size: 14px;
              color: #999999;
              margin-top: 10px;
              padding-bottom: 20px;
              display: flex;
              justify-content: space-between;
              align-items: center;
            "
          >
            <span> 您可以在此设置首公里转运仓以及寄送方式</span>
          </div>
          <div class="default" v-if="listData.base_info">
            <div style="font-size: 18px; margin-bottom: 10px">默认转运仓</div>
            <div style="font-size: 14px; margin-bottom: 10px; color: #999">
              请选择卖家始发地区的Shopee转运仓。货拉拉类和目送仓库卖家仅需要确认您寄送的Shopee仓库即可。
            </div>
            <div class="cell">
              <div class="name">
                <div style="font-size: 14px">
                  <span>{{
                    wareList[listData.base_info.default_transfer_bin]
                  }}</span>
                </div>
                <div>
                  <i
                    @click="opens(1)"
                    v-if="show2"
                    class="el-icon-arrow-up"
                    style="cursor: pointer"
                  ></i>
                  <i
                    style="cursor: pointer"
                    @click="opens(1)"
                    v-else
                    class="el-icon-arrow-down"
                  ></i>
                </div>
              </div>
              <div class="cell-hidden" v-if="show2">
                <el-radio-group
                  @change="editSet(listData.base_info.id, $event, 6)"
                  v-model="listData.base_info.default_transfer_bin"
                >
                  <el-radio
                    style="display: block; margin-bottom: 20px"
                    :label="index"
                    v-for="(item, index) in wareList"
                    :key="index"
                    >{{ item }}</el-radio
                  >
                </el-radio-group>
              </div>
            </div>
          </div>
          <div class="default" v-if="listData.base_info">
            <div style="font-size: 18px; margin-bottom: 20px">默认寄送方式</div>
            <div style="font-size: 14px; margin-bottom: 10px; color: #999">
              请选择头程的寄送方式
            </div>
            <div class="cell">
              <div class="name">
                <div style="font-size: 14px">
                  <span>{{ options[listData.base_info.delivery_type] }}</span>
                </div>
                <div>
                  <i
                    @click="opens"
                    v-if="show"
                    class="el-icon-arrow-up"
                    style="cursor: pointer"
                  ></i>
                  <i
                    style="cursor: pointer"
                    @click="opens"
                    v-else
                    class="el-icon-arrow-down"
                  ></i>
                </div>
              </div>
              <div class="cell-hidden" v-if="show">
                <el-radio-group
                  @change="editSet(listData.base_info.id, $event, 4)"
                  v-model="listData.base_info.delivery_type"
                >
                  <el-radio
                    style="display: block; margin-bottom: 20px"
                    :label="index"
                    v-for="(item, index) in options"
                    :key="index"
                    >{{ item }}</el-radio
                  >
                </el-radio-group>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="in">
        <div>
          <i
            class="el-icon-date"
            style="font-size: 24px; color: #999999; margin-top: 40px"
          ></i>
        </div>
        <div
          style="
            margin-left: 20px;
            margin-right: 30px;
            width: 100%;
            border-top: 1px solid #e8e8e8;
            padding-top: 20px;
          "
        >
          <div style="font-size: 18px">出货天数</div>
          <div
            style="
              font-size: 14px;
              color: #999999;
              margin-top: 10px;
              padding-bottom: 20px;
              display: flex;
              justify-content: space-between;
              align-items: center;
            "
          >
            <span> 一键修改所有架上商品的出货天数</span>
            <el-button size="small" plain @click="dialogVisible = true"
              >修 改</el-button
            >
          </div>
        </div>
      </div>
      <div class="bottom" v-if="listData.base_info">
        <div>
          <i
            class="el-icon-sold-out"
            style="font-size: 24px; margin-top: 28px; color: #999999"
          ></i>
        </div>
        <div
          style="
            margin-left: 20px;
            margin-right: 30px;
            width: 100%;
            border-top: 1px solid #e8e8e8;
            padding-top: 10px;
          "
        >
          <div style="font-size: 18px">退回卖家</div>
          <div
            style="
              font-size: 14px;
              color: #999999;
              margin-top: 10px;
              padding-bottom: 20px;
              margin-bottom: 40px;
              display: flex;
              justify-content: space-between;
              align-items: center;
            "
          >
            <span style="margin-right: 10px">
              从2002年开始，卖家跨境退货将基于店铺维度确认。从04/01/2022开始，所有创建的订单中的配送失败和买家退货退款需要跨境退货的商品，如若高于或等于包裹退运门槛20美元，商品将会在本地仓库收到以后寄还给您，如若低于20美元，商品将在本地处置，所有跨境退回商品需遵守平台的跨境退货政策要求。除非您之后对下一季度的退回卖家选项做了新的更改。</span
            >
            <el-switch
              v-model="listData.base_info.is_return"
              active-color="#13ce66"
              :active-value="1"
              :inactive-value="0"
              @change="
                editSet(listData.base_info.id, listData.base_info.is_return, 5)
              "
            >
            </el-switch>
          </div>
        </div>
      </div>
    </div>

    <el-dialog
      title="一键修改出货天数"
      :visible.sync="dialogVisible"
      width="600px"
      :before-close="handleClose"
    >
      <div>
        以下功能为您商品中所有已上架商品提供"出货天数"的一次性更新。请注意，您仅能为已上架商品更新至3/5/10天。
      </div>
      <div
        style="
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 40px;
        "
      >
        出货天数：
        <el-input
          style="width: 250px"
          v-model="dayNum"
          placeholder="请输入3/5/10天的出货天数"
        ></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取消</el-button>
        <el-button type="primary" @click="editDay">更新</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { setting, editSetting, editShipDay } from "@/api/logistics.js";
export default {
  data() {
    return {
      show: false,
      show2: false,
      listData: {
        shipping_list: [],
      },
      options: [
        "非快递寄送（包括：免费/付费揽收，集货点）",
        "快递寄送（不包括：货拉拉类卖家，自送仓库）",
        "自送卖家",
      ],
      wareList: [
        "Shopee上海仓",
        "Shopee泉州仓",
        "Shopee义乌仓",
        "Shopee华南仓",
        "Shopee香港仓",
      ],
      dialogVisible: false,
      dayNum: "",
    };
  },
  created() {
    this.geiList();
  },
  methods: {
    geiList() {
      setting().then((res) => {
        if (res.code == 1) {
          this.listData = res.data;
          this.listData.shipping_list.forEach((item) => {
            // if (item.is_default == 1) {
            //   item.open = true;
            // } else {
              item.open = false;
            // }
          });
        }
      });
    },
    open(item) {
      item.open = item.open ? false : true;
      this.$forceUpdate();
    },
    opens(type) {
      if (type == 1) {
        this.show2 = this.show2 ? false : true;
      } else {
        this.show = this.show ? false : true;
      }
    },
    editSet(id, value, type) {
      editSetting({
        id: id,
        type: type,
        val: value,
      }).then((res) => {
        if (res.code == 1) {
          if (type == 3) {
            this.listData.shipping_list.forEach((val) => {
              if (val.id != id) {
                val.is_default = 0;
                this.$forceUpdate();
              }
            });
          }
        }
        if (res.code == 0) {
          if (type == 3) {
            this.listData.shipping_list.forEach((val) => {
              if (val.id == id) {
                val.is_default = 1;
                this.$forceUpdate();
              }
            });
          }
        }
      });
    },
    editDay() {
      editShipDay({
        day: this.dayNum,
      }).then((res) => {
        if (res.code == 1) {
          this.$message.success(res.message);
          this.handleClose();
        }
      });
    },
    handleClose() {
      this.dialogVisible = false;
      this.dayNum = "";
    },
    handleChange(a, b, c) {
      // this.editSet
    },
  },
};
</script>
<style lang="less" scoped>
.logisticsSet {
  .container {
    // width: 1400px;
    width: 100%;
    margin: 0 auto;
    padding: 30px 0;
    background: #fff;
    .title {
      font-size: 22px;
      margin-bottom: 10px;
      font-weight: 500;
      border-bottom: 1px solid #e8e8e8;
      padding: 0 0 30px 30px;
    }
    .top {
      padding-top: 20px;
      padding-left: 30px;
      display: flex;
      .cash-cell {
        border: 1px solid #e8e8e8;
        border-radius: 4px;
        margin-right: 30px;
        padding: 20px;
        margin-bottom: 20px;
        .name {
          display: flex;
          justify-content: space-between;
        }
        .cell-hidden {
          display: flex;
          justify-content: space-between;
          margin-top: 20px;
          border-top: 1px solid #e8e8e8;
          .r-item {
            width: 45%;
            font-size: 14px;
            div {
              display: flex;
              justify-content: space-between;
              padding: 10px 0;
            }
          }
        }
      }
      .default {
        border-top: 1px solid #e8e8e8;
        margin-top: 20px;
        padding: 20px 0 0 0;
        margin-right: 30px;
        .cell {
          border: 1px solid #e8e8e8;
          border-radius: 4px;
          margin-right: 30px;
          padding: 20px;
          margin-bottom: 20px;
          .name {
            display: flex;
            justify-content: space-between;
          }
          .cell-hidden {
            border-top: 1px solid #e8e8e8;
            margin-top: 20px;
            padding-top: 30px;
          }
        }
      }
    }
    .in {
      padding-top: 10px;
      padding-left: 30px;
      display: flex;
    }
    .bottom {
      padding-left: 30px;
      display: flex;
      padding-top: 10px;
    }
  }
}
</style>